import React, { useState, useEffect } from 'react';
import './ChatComponent.css';

const ChatComponent = ({ missionNumber, dispatcherId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    // Fetch initial comments from the mock API
    fetchComments();
  }, []);

  const fetchComments = () => {
    // Mock API response
    const mockApiResponse = [
      {
        timestamp: '2023-04-14T12:00:00.000Z',
        dispatcherId: 'D1',
        dispatcherInitials: 'JD',
        comment: 'Comment 1',
      },
      {
        timestamp: '2023-04-14T11:00:00.000Z',
        dispatcherId: 'D2',
        dispatcherInitials: 'AB',
        comment: 'Comment 2',
      },
    ];

    // Update the comments state
    setComments(mockApiResponse);
  };

// Utility function to convert URLs in text to HTML links
const convertUrlsToLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = (e) => {
    // Prevent the page from refreshing
    e.preventDefault();

    if (newComment.trim() === '') {
      return;
    }

    // Submit the new comment to the mock API
    const newCommentObj = {
      timestamp: new Date().toISOString(),
      dispatcherId: dispatcherId,
      dispatcherInitials: 'JD', // This should be retrieved from the backend
      comment: newComment,
    };

    // Update the comments state
    setComments([newCommentObj, ...comments]);

    // Clear the input field
    setNewComment('');
  };

  return (
    <div className="chat-container">
      <div className="new-comment-container">
        <input
          type="text"
          value={newComment}
          onChange={handleInputChange}
          placeholder="Add Comment Here"
        />
        <button onClick={handleSubmit}>➔</button>
      </div>
      <div className="comments-container">
        {comments
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map((comment, index) => (
            <div key={index} className="comment">
              <span className="comment-timestamp">
                {new Date(comment.timestamp).toLocaleString('en-US', {
                  timeZone: 'America/Los_Angeles',
                })}
              </span>
              <span className="comment-initials">{comment.dispatcherInitials}</span>
              <span
                className="comment-text"
                dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(comment.comment) }}
              ></span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ChatComponent;
