import React, { useEffect, useState } from 'react';

const LocationMap = ({ locationInput }) => {
  const [mapSrc, setMapSrc] = useState(null);

  const isGpsCoordinate = (input) => {
    return /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/.test(input);
  };

  const isDmsCoordinate = (input) => {
    return /^(\d{1,3}°\d{1,2}'\d{1,2}"[NS],\d{1,3}°\d{1,2}'\d{1,2}"[EW])$/.test(input);
  };

  const dmsToDd = (dms) => {
    const match = dms.match(/(\d{1,3})°(\d{1,2})'(\d{1,2})"([NS]),(\d{1,3})°(\d{1,2})'(\d{1,2})"([EW])/);
    const lat = Number(match[1]) + Number(match[2])/60 + Number(match[3])/3600;
    const lng = Number(match[5]) + Number(match[6])/60 + Number(match[7])/3600;
    return `${match[4] === 'N' ? lat : -lat},${match[8] === 'E' ? lng : -lng}`;
  };

  useEffect(() => {
    let src;
    if (isGpsCoordinate(locationInput)) {
      const [lat, lng] = locationInput.split(',').map(Number);
      src = `https://www.google.com/maps/embed/v1/view?key=AIzaSyABx2YfZCccQIcEQ0h4RqUP74OZajM0OYs&center=${lat},${lng}&zoom=15`;
    } else if (isDmsCoordinate(locationInput)) {
      const ddCoordinates = dmsToDd(locationInput);
      const [lat, lng] = ddCoordinates.split(',').map(Number);
      src = `https://www.google.com/maps/embed/v1/view?key=AIzaSyABx2YfZCccQIcEQ0h4RqUP74OZajM0OYs&center=${lat},${lng}&zoom=15`;
    } else if (locationInput) {
      src = `https://www.google.com/maps/embed/v1/place?key=AIzaSyABx2YfZCccQIcEQ0h4RqUP74OZajM0OYs&q=${encodeURIComponent(locationInput)}`;
    }

    setMapSrc(src);
  }, [locationInput]);

  if (!mapSrc) {
    return null;  // Do not render the map if location input is not provided
  }

  return (
    <iframe
      width="100%"
      height="300px"
      frameBorder="0"
      style={{ border: 0 }}
      src={mapSrc}
      allowFullScreen
    />
  );
};

export default LocationMap;
