// api.js

// import { getAccessTokenSilently } from "@auth0/auth0-react"; // Import this function



const getHeaders = async (getAccessTokenSilently) => {
  const token = await getAccessTokenSilently();
  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

// const BASE_URL = 'http://127.0.0.1:8000'; // Replace with the actual hostname and port of your Flask backend
const BASE_URL = 'https://dispatch.helirescue.org/api';
//
// const BASE_URL = 'http://dispatch.helirescue.org:8000'
//
console.log(BASE_URL);

export async function fetchMissionNumber() {
  console.log('Fetching mission number...');
  try {
    const response = await fetch(`${BASE_URL}/generateMissionNumber`);
    if (!response.ok) {
      console.error('Response not OK:', response);
    }
    const data = await response.json();
    console.log('Fetched mission number:', data.missionNumber);
    return data.missionNumber;
  } catch (error) {
    console.error('Error fetching mission number:', error);
  }
}


export const fetchMissions = async () => {
  try {
    const response = await fetch(`${BASE_URL}/missions`, {});
    const missions = await response.json();
    return missions;
  } catch (error) {
    console.error('Error fetching missions:', error);
    return [];
  }
};

export const createMission = async (mission) => {
  try {
      const response = await fetch(`${BASE_URL}/missions`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(mission),
      });

      if (!response.ok) {
          const errorData = await response.json();
          console.error('Error creating mission:', errorData);
          return null;
      }

      const newMission = await response.json();
      return newMission;
  } catch (error) {
      console.error('Error creating mission:', error);
      return null;
  }
};


export const fetchMission = async (missionId) => {
  try {
    const response = await fetch(`${BASE_URL}/missions/${missionId}`);
    const mission = await response.json();
    return mission;
  } catch (error) {
    console.error(`Error fetching mission with ID ${missionId}:`, error);
    return null;
  }
};

export const updateMission = async (missionId, mission) => {
  try {
    const response = await fetch(`${BASE_URL}/missions/${missionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mission),
    });
    const updatedMission = await response.json();
    return updatedMission;
  } catch (error) {
    console.error(`Error updating mission with ID ${missionId}:`, error);
    return null;
  }
};

export const fetchCallTypes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/callTypes`);
    const callTypes = await response.json();
    return callTypes;
  } catch (error) {
    console.error('Error fetching call types:', error);
    return [];
  }
};
