import React, { useState, useEffect, useRef } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './MissionDetailPage.css'; // Import the custom CSS file
import LocationMap from './LocationMap';
import ChatComponent from './ChatComponent'; // Import the ChatComponent
import { priorities, getPriorityLabel } from './utils';
import { useParams } from 'react-router-dom';
import { fetchMission, createMission, updateMission, fetchMissionNumber, fetchCallTypes } from './api';

// const BASE_URL = 'http://127.0.0.1:8000'; // Replace with the actual hostname and port of your Flask backend
const BASE_URL = 'https://dispatch.helirescue.org/api';
//
//const BASE_URL = 'http://dispatch.helirescue.org:8000'

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${formattedDate} ${hours}:${minutes}`;
};

const MissionDetailPage = ({ onClose, isNew }) => {
  const { missionNumber } = useParams();

  const [mission, setMission] = useState(
    isNew
      ? {
        missionDateTime: new Date().toISOString(),
        missionCreated: new Date().toISOString(),
        missionUpdated: new Date().toISOString(),
        missionStatus: 'open', // default value
        missionClosed: '',  // Not for display/editing
        // ... other properties as needed
      }
      : null
  );

  const locationAddressRef = useRef(null);

  const handleLocationKeyDown = (event) => {
    if (event.key === 'Enter') {
      // Prevent form submission on Enter
      event.preventDefault();

      // Update the mission.locationAddress state and call the LocationMap component
      setMission(prevMission => ({
        ...prevMission,
        locationAddress: locationAddressRef.current.value
      }));
    }
  };

  const handleLocationBlur = () => {
    // Update the mission.locationAddress state and call the LocationMap component
    setMission(prevMission => ({
      ...prevMission,
      locationAddress: locationAddressRef.current.value
    }));
  };

  const [callTypes, setCallTypes] = useState([]);

  const handleCloseMission = () => {
    if (window.confirm("Are you sure you want to close this incident?")) {
      const currentTimestamp = new Date().toISOString();
      const closedMission = { 
        ...mission, 
        missionStatus: 'closed', 
        missionClosed: currentTimestamp // Include missionClosed here
      };
      updateMission(mission.missionNumber, closedMission).then((data) => {
        setMission(data);
        window.location.href = '/mission-list';
      });
    }
  };
  


  useEffect(() => {
    const loadMission = async () => {
      if (isNew) {
        const missionNumber = await fetchMissionNumber();
        setMission({
          missionNumber: missionNumber,
          missionDateTime: new Date().toISOString(),
          missionCreated: new Date().toISOString(),
          missionUpdated: new Date().toISOString(),
          missionStatus: 'open', // default value
          missionClosed: '',  // Not for display/editing
          primaryDispatcher: '',
          priority: '',
          callType: '',
          primaryBase: '',
          chiefComplaint: '',
          descriptionNotes: '',
          callerName: '',
          callbackNumber: '',
          radioFrequency: '',
          callerNotes: '',
          locationAddress: '',
          weatherAtScene: '',
          methodOfMarkingScene: '',
          knownHazards: '',
          groundAccessible: '',
          specialEquipmentRequired: '',
          otherResponders: '',
          expectedDestination: '',
          transportArranged: '',
          destinationComments: '',
          patientName: '',
          patientAge: '',
          patientWeight: '',
          patientInjuries: '',
          patientComments: '',
        });
      } else if (missionNumber) { // Check if missionNumber prop is provided
        const missionData = await fetchMission(missionNumber);
        setMission(missionData);
      }
    };

    const loadCallTypes = async () => {
      const callTypesData = await fetchCallTypes();
      setCallTypes(callTypesData);
    };

    // Invoke the functions
    loadMission();
    loadCallTypes();
  }, [missionNumber, isNew]); // missionNumber and isNew are dependencies


  const dispatcherId = 'JV';

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedMission = { ...mission, [name]: value };
    setMission(updatedMission);
  };

  const handleSave = () => {
    // Generate a new ISO 8601 date-time string for the current time
    const now = new Date().toISOString();

    if (isNew) {
      // If the mission is new, set both 'missionCreated' and 'missionUpdated' to the current time
      setMission(mission => ({ ...mission, missionCreated: now, missionUpdated: now }));

      // Make an API call to create a new mission
      console.log(mission);  // Add this line to log the mission data
      fetch(BASE_URL + '/missions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mission),
      })
        .then(response => response.json())
        .then(data => {
          setMission(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      // If the mission is being updated, only 'missionUpdated' should be changed
      setMission(mission => ({ ...mission, missionUpdated: now }));

      // Make an API call to update the existing mission
      console.log(mission);  // Add this line to log the mission data
      fetch(BASE_URL + '/missions/' + mission.missionNumber, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mission),
      })
        .then(response => response.json())
        .then(data => {
          setMission(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };


  const handleCloseClick = () => {
    // Redirect to the MissionListPage
    window.location.href = '/mission-list';
  };

  // Check if the mission object is not null before rendering mission details
  if (!mission) {
    return <div>Loading...</div>; // Render a loading message or spinner
  }

  return (
    <div className="container mission-detail-page">
      {/* Check if the mission object is not null before rendering mission details */}
      {mission ? (
        <>
          <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
            <h2 className="mb-0 mission-detail-title">Mission Detail</h2>
            <div className="button-container d-flex justify-content-between mt-4">
      <div>
        <button className="btn btn-primary me-2" onClick={handleSave}>
          Save Mission
        </button>
        <button className="btn btn-secondary me-2" onClick={handleCloseClick}>
          Exit to Main
        </button>
      </div>
      <div>
        <button className="btn btn-danger" onClick={handleCloseMission}>
          Close Mission
        </button>
      </div>
    </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-4 mission-summary-header">
            <div className="mission-summary-info-item">
              <span className="mission-summary-label">Mission Created:</span> {formatDateTime(mission.missionCreated)}
              <div>
                <span className="mission-summary-label">Mission Updated:</span>
                {formatDateTime(mission.missionUpdated) !== "NaN-NaN-NaN NaN:NaN"
                  ? formatDateTime(mission.missionUpdated)
                  : formatDateTime(mission.missionCreated)}
              </div>
            </div>

            <div className="mission-summary-info-item">
              <span className="mission-summary-label">Mission Number:</span> {mission && mission.missionNumber ? mission.missionNumber.slice(0, 4) + mission.missionNumber.slice(4) : ''}

            </div>


          </div>
          <form>
            <div className="card mb-4 mb-4 mission-summary-card">
              <div className="card-header bg-primary text-white">Mission Summary</div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Priority:</label>
                    <select
                      name="priority"
                      className="form-select"
                      value={mission.priority || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Priority</option>
                      <option value="GREEN">GREEN</option>
                      <option value="YELLOW">YELLOW</option>
                      <option value="ORANGE">ORANGE</option>
                      <option value="RED">RED</option>
                      <option value="PURPLE">PURPLE</option>

                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Call Type:</label>
                    <select
                      name="callType"
                      className="form-select"
                      value={mission.callType || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Call Type</option>
                      {callTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Primary Base:</label>
                    <select
                      name="primaryBase"
                      className="form-select"
                      value={mission.primaryBase || ''}
                      onChange={handleChange}
                    >
                      <option value="">Select Primary Base</option>
                      <option value="1-Squamish">1-Squamish</option>
                      <option value="2-Kamloops">2-Kamloops</option>
                      <option value="3-Prince George">3-Prince George</option>
                      <option value="4-Campbell River">4-Campbell River</option>
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <label className="form-label">Chief Complaint (Nature of Incident):</label>
                    <input
                      type="text"
                      className="form-control"
                      name="chiefComplaint"
                      value={mission.chiefComplaint || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <label className="form-label">Description/Notes:</label>
                    <textarea
                      className="form-control"
                      name="descriptionNotes"
                      rows="3"
                      value={mission.descriptionNotes || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-4 caller-info-card">
              <div className="card-header bg-primary text-white">Caller and Callback Info</div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label className="form-label">Caller Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="callerName"
                      value={mission.callerName || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">Callback Number:</label>
                    <input
                      type="text"
                      className="form-control"
                      name="callbackNumber"
                      value={mission.callbackNumber || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Radio Frequency:</label>
                    <textarea
                      className="form-control"
                      name="radioFrequency"
                      rows="2"
                      value={mission.radioFrequency || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-8">
                    <label className="form-label">Caller Notes:</label>
                    <textarea
                      className="form-control"
                      name="callerNotes"
                      rows="3"
                      value={mission.callerNotes || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4 location-info-card">
              <div className="card-header bg-primary text-white">Location Information</div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Address/GPS Coordinates:</label>
                    <textarea
                      className="form-control"
                      name="locationAddress"
                      ref={locationAddressRef}
                      defaultValue={mission.locationAddress || ''}
                      onKeyDown={handleLocationKeyDown}
                      onBlur={handleLocationBlur}
                    ></textarea>

                  </div>
                </div>
                <LocationMap locationInput={mission.locationAddress} />
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Weather at Scene and Ceiling:</label>
                    <textarea
                      className="form-control"
                      name="weatherAtScene"
                      rows="2"
                      value={mission.weatherAtScene || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Method of Marking Scene:</label>
                    <textarea
                      className="form-control"
                      name="methodOfMarkingScene"
                      rows="2"
                      value={mission.methodOfMarkingScene || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Known Hazards:</label>
                    <textarea
                      className="form-control"
                      name="knownHazards"
                      rows="2"
                      value={mission.knownHazards || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="groundAccessible">Ground Accessible:</label>
                    <textarea
                      id="groundAccessible"
                      name="groundAccessible"
                      rows="3"
                      className="form-control"
                      value={mission.groundAccessible}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {/* ... (Suitable Landing Zone and Extrication Required) */}
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Special Equipment or Personnel Required:</label>
                    <textarea
                      className="form-control"
                      name="specialEquipmentRequired"
                      rows="2"
                      value={mission.specialEquipmentRequired || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Other Responders or Aircraft in the Area:</label>
                    <textarea
                      className="form-control"
                      name="otherResponders"
                      rows="2"
                      value={mission.otherResponders || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* Destination section */}
            <div className="card mb-4 destination-card">
              <div className="card-header bg-primary text-white">Destination</div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Expected Destination:</label>
                    <textarea
                      className="form-control"
                      name="expectedDestination"
                      rows="2"
                      value={mission.expectedDestination || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="transportArranged">Transport Arranged:</label>
                    <textarea
                      id="transportArranged"
                      name="transportArranged"
                      rows="3"
                      className="form-control"
                      value={mission.transportArranged}
                      onChange={handleChange}
                    />
                  </div>
                </div>






                <div className="row mb-3">
                  <div className="col-12">
                    <label className='form-label'>Comments:</label>
                    <textarea
                      className="form-control"
                      name="destinationComments"
                      rows="4"
                      value={mission.destinationComments || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            {/* Patient Info section */}
            <div className="card mb-4 patient-info-card">
              <div className="card-header bg-primary text-white">Patient Info</div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12">
                    <label className='form-label'>Patient Name:</label>
                    <textarea
                      className="form-control"
                      name="patientName"
                      rows="1"
                      value={mission.patientName || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className='form-label'>Age:</label>
                    <textarea
                      className="form-control"
                      name="patientAge"
                      rows="1"
                      value={mission.patientAge || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <label className='form-label'>Patient Weight:</label>
                    <textarea
                      className="form-control"
                      name="patientWeight"
                      rows="1"
                      value={mission.patientWeight || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>





                <div className="row mb-3">
                  <div className="col-12">
                    <label className='form-label'>Patient Injuries:</label>
                    <textarea
                      className="form-control"
                      name="patientInjuries"
                      rows="3"
                      value={mission.patientInjuries || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <label className="form-label">Comments:</label>
                    <textarea
                      className="form-control"
                      name="patientComments"
                      rows="5"
                      value={mission.patientComments || ''}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-4 comments-card">
              <div className="card-header bg-primary text-white">Comments</div>
              <div className="card-body chat-container">
                <ChatComponent missionNumber={missionNumber} dispatcherId={dispatcherId} />

              </div>
            </div>
          </form>
        </>
      ) : null}
    </div>
  );
}; // Add the missing closing brace here


export default MissionDetailPage;
