import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const callbackUrl = process.env.NODE_ENV === 'production' ? "https://dispatch.helirescue.org" : "http://localhost:3000";

// Print window.location.origin to the console
console.log("window.location.origin:", window.location.origin);

root.render(
  <React.StrictMode>

<Auth0Provider
  domain="dev-bmjrf27nboqvk6x7.us.auth0.com"
  clientId="CG7zpwBzNlpIaloxgICCrxJLE4lbwyOo"
  redirectUri="https://dispatch.helirescue.org"
>
  <App />
</Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
