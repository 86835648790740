import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import MissionListPage from './MissionListPage';
import MissionDetailPage from './MissionDetailPage';

const ProtectedMissionListPage = withAuthenticationRequired(MissionListPage);
const ProtectedMissionDetailPage = withAuthenticationRequired(MissionDetailPage);

const App = () => {
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      {/* <div>
        {isAuthenticated ? (
          <button onClick={() => logout()}>Logout</button>
        ) : null}
      </div> */}

      <Routes>
        <Route path="/" element={<ProtectedMissionListPage />} />
        <Route path="/mission-list" element={<ProtectedMissionListPage />} />
        <Route path="/mission-detail/new" element={<ProtectedMissionDetailPage isNew />} />
        <Route path="/mission-detail/:missionNumber" element={<ProtectedMissionDetailPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
