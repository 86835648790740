import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { fetchMissions } from './api';
import { getPriorityLabel } from './utils';
import './MissionListPage.css';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${formattedDate} ${hours}:${minutes}:${seconds}`;
};

// Auth0 Profile retrieval

function getInitials(name) {
  return name.split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
}


// const Profile = () => {
//   const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
//   const [userMetadata, setUserMetadata] = useState(null);

//   return (
//     isAuthenticated && (
//       <div>
//         <img src={user.picture} alt={user.name} />
//         <h2>{user.name}</h2>
//         <p>{user.email}</p>
//         <h3>User Metadata</h3>
//         {userMetadata ? (
//           <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
//         ) : (
//           "No user metadata defined"
//         )}
//       </div>
//     )
//   );
// };


const priorityToBootstrapClass = (priority) => {
  switch (priority) {
    case 'GREEN':
      return 'bg-success text-white';
    case 'YELLOW':
      return 'bg-warning text-dark';
    case 'ORANGE':
      return 'bg-orange text-white'; // Note: Bootstrap doesn't have a built-in orange class
    case 'RED':
      return 'bg-danger text-white';
    case 'PURPLE':
      return 'bg-purple text-white'; // Note: Bootstrap doesn't have a built-in purple class
    default:
      return 'bg-light text-dark';
  }
};

const MissionListPage = () => {
  const [missions, setMissions] = useState([]);
  const [showClosed, setShowClosed] = useState(false); // New state for showing closed missions
  const navigate = useNavigate(); // Use useNavigate hook
  const location = useLocation();

  // auth
  const { logout, isAuthenticated, getTokenSilently } = useAuth0();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logout();
    }
  };

  const [showDebugInfo, setShowDebugInfo] = useState(false);

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     const domain = "{yourDomain}";
  
  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         authorizationParams: {
  //           audience: `https://${domain}/api/v2/`,
  //           scope: "read:current_user",
  //         },
  //       });
  
  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
  
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });
  
  //       const { user_metadata } = await metadataResponse.json();
  
  //       setUserMetadata(user_metadata);
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };
  
  //   getUserMetadata();
  // }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    async function loadMissions() {
      // const token = await getTokenSilently();
 
      // const missionsData = await fetchMissions(token);

      const missionsData = await fetchMissions();

      if (Array.isArray(missionsData)) {
        setMissions(missionsData);
      } else {
        console.error('API did not return an array:', missionsData);
      }

      // setMissions(missionsData);
    }
    loadMissions();
  }, [location,getTokenSilently]);

  const handleCreateMission = () => {
    navigate('/mission-detail/new'); // Redirect using navigate
  };

  // Filter the missions based on the state of showClosed
  const filteredMissions = missions.filter((mission) => showClosed || mission.missionStatus === 'open');

  return (
    <div className="container mission-list-page">
      <nav className="navbar navbar-light bg-light"> {/* Add this navbar */}
        <div className="container-fluid">
          <a className="navbar-brand text-uppercase font-weight-bold text-primary" href="#">
            TEAAM <span className="text-danger">Mission</span> Manager
          </a>

          <button className="btn btn-outline-primary me-2" onClick={handleCreateMission}>
            Create New Incident
          </button>
          <button className="btn btn-outline-secondary me-2" onClick={() => setShowClosed(!showClosed)}>
            {showClosed ? 'Hide Closed' : 'Show Closed'}
          </button>

          {isAuthenticated && (
            <button className="btn btn-outline-danger" onClick={handleLogout}>
              Logout
            </button>
          )}
        </div>
      </nav>

  


      <div className="header-container">
        <h2 className="title">Missions</h2>
      </div>
      <table className="table table-striped mission-list-table">
        <thead className="table-header">
          <tr>
            <th className="col-created">Mission Created</th>
            <th className="col-priority">Priority</th>
            <th className="col-number">Mission Number</th>
            <th className="col-call-type">Call Type</th>
            <th className="col-incident">Nature of Incident</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredMissions.map((mission) => ( // Change to filteredMissions
            <tr key={mission.missionNumber}>
              <td className="col-created">{formatDateTime(mission.missionCreated)}</td>
              <td>
                <div
                  className={`rounded text-center p-1 ${priorityToBootstrapClass(mission.priority)}`}
                  style={{ width: '60px', fontSize: '12px' }}
                >
                  {mission.priority}
                </div>
              </td>
              <td className="col-number">
                <a href={`/mission-detail/${mission.missionNumber}`}>
                  {mission.missionNumber.slice(0, 4)}-{mission.missionNumber.slice(4)}
                </a>
              </td>
              <td className="col-call-type">{mission.callType}</td>
              <td className="col-incident">{mission.chiefComplaint}</td>
              <td>
                <div
                  className={`rounded text-center p-1 ${mission.missionStatus === 'closed' ? 'bg-dark text-white' : 'bg-success text-dark'}`}
                  style={{ width: '60px', fontSize: '12px' }}
                >
                  {mission.missionStatus}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => setShowDebugInfo(!showDebugInfo)}>Toggle Debug Info</button>
      {
        showDebugInfo &&
        <pre>{JSON.stringify(missions, null, 2)}</pre>
      }


    </div>
  );
};

export default MissionListPage;
